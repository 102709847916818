.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-class-popup {
  position: fixed;
  background-color: var(--blue-900);
  width: 46.389em;
  height: 25em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-top: 2.639em;
  padding-left: 4.931em;
}

.demo-class-popup__close-button {
  color: white;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  font-size: 4rem;
  position: absolute;
  top: .05em;
  right: .1em;
  width: .8em;
  height: .8em;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo-class-popup__title {
  color: var(--sky-blue-two);
  width: 8em;
  margin: 0;
}
.demo-class-popup__description {
  color: var(--turquoise-300);
  font-size: 1.25rem;
  font-weight: 800;
  width: 19em;
  margin-top: .661em;
  margin-bottom: 1.895em;
}
.demo-class-popup__signin-button {
  background-color: var(--gray-blue);
  color: var(--purple);
  display: flex;
  z-index: 2;
}
.demo-class-popup__image-container {
  width: 26em;
  height: max-content;
  margin: 0;
  position: absolute;
  top: -.6em;
  right: -1.8em;
}
.demo-class-popup__image-container img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .demo-class-popup {
    width: 40.404em;
    height: max-content;
    padding: 4.598em 0 6.131em 0;
  }
  .demo-class-popup__image-container {
    width: 25em;
    top: 18.5em;
    right: -1em;
  }
  .demo-class-popup__text-container {
    margin-left: 6.827em;
  }
  .demo-class-popup__description {
    font-size: 2.508rem;
    width: 7em;
    line-height: 1.167em;
  }
  .demo-class-popup__signin-button {
    margin: auto;
  }
  .demo-class-popup__close-button {
    font-size: 7rem;
  }
}